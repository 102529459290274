:root {
  --hue-primary: 220;
  --hue-link: 195;
  --hue-correct: 170;
  --hue-incorrect: 0;
  --saturation-bg: 35%;
  --saturation-lowest: 20%;
  --saturation-lower: 30%;
  --saturation-low: 40%;
  --saturation-medium-low: 50%;
  --saturation-medium: 60%;
  --saturation-medium-high: 70%;
  --saturation-high: 70%;
  --saturation-highest: 90%;
  --saturation-text: 35%;
  --lower: 35%;
  --low: 40%;
  --medium-low: 50%;
  --medium: 60%;
  --medium-high: 70%;
  --high: 80%;
  --highest: 90%;
  --brightness-text: 45%;
  --modal-background: rgba(207, 210, 228, 0.7);
  --card-r: 242;
  --card-g: 234;
  --card-b: 248;
  --card-white-r: 255;
  --card-white-g: 255;
  --card-white-b: 255;
  --white: 255;
  --image-opacity: 1;
  --opacity-unclickable: 0.5;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  border: none;
  outline: none;
}

*:active {
  outline: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  caret-color: transparent;
}

html {
  background: linear-gradient(165deg, rgb(245, 253, 253) 0%, rgb(217, 232, 240) 100%);
}

html,
body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: Inter, Mona Sans, poppins, sans-serif;
}

.App {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  color: hsl(205, 50%, 40%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 0 2em;
  z-index: 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
main h4 {
  font-size: 0.7em;
  padding-bottom: 0.2em;
  font-weight: normal;
}

.section {
  margin: 0 auto;
  max-width: 1250px;
  padding-top: 4em;
  padding-bottom: 5em;
}
@media screen and (max-width: 500px) {
  .section {
    padding-top: 1em;
    padding-bottom: 3em;
  }
}

.sectionHeader {
  font-size: 2.7em;
  font-weight: 600;
  color: hsl(205, 60%, 30%);
  text-align: left;
}
@media screen and (max-width: 500px) {
  .sectionHeader {
    font-size: 1.7em;
  }
}

h1,
h2,
h3,
h4,
h5,
p,
table {
  margin: 0;
  padding: 0;
}

p {
  margin: 1em 0;
  color: hsl(205, 70%, 20%);
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table,
th,
td {
  border: none;
}

th {
  padding: 0;
  text-align: left;
  color: hsl(205, 50%, 40%);
  font-weight: 500;
}

sup {
  font-size: 0.5em;
  line-height: 1em;
  margin-top: -0.5em;
}

h1 {
  font-size: 1.3em;
  letter-spacing: 0.1rem;
  font-weight: 400;
  color: hsl(205, 50%, 40%);
}
@media screen and (max-width: 500px) {
  h1 {
    font-size: 1.1em;
  }
}

h2 {
  font-size: 1.1em;
  line-height: 1.1;
  font-weight: 500;
  color: hsl(205, 40%, 60%);
}
@media screen and (max-width: 500px) {
  h2 {
    font-size: 0.9em;
  }
}

h3 {
  font-size: 1.2em;
  color: hsl(205, 40%, 40%);
}
@media screen and (max-width: 500px) {
  h3 {
    font-size: 0.9em;
  }
}

h5 {
  font-size: 0.5em;
  font-weight: 600;
}

a,
.link {
  text-decoration: none;
  color: hsl(195, 70%, 40%);
  font-weight: 500;
  transition: 0.5s;
  cursor: pointer;
}
a:hover,
.link:hover {
  color: hsl(195, 60%, 60%);
}

.error {
  color: hsl(350, 60%, 60%);
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

input,
button,
select,
textarea {
  font-family: Inter, Mona Sans, poppins, sans-serif;
  font-size: 1em;
  width: 100%;
  padding: 1em 1.2em 1em 1.2em;
  border-radius: 0.5em;
  border: none;
  background: none;
  caret-color: black;
}
input:focus,
button:focus,
select:focus,
textarea:focus {
  border: none;
  outline: none;
}

input {
  margin: 0;
  height: 3em;
  caret-color: black;
  box-shadow: 0 0px 1px rgba(91, 133, 168, 0.5), 0 0px 2px rgba(91, 133, 168, 0.4), 0 0px 8px rgba(133, 171, 197, 0.02), 0 0px 16px rgba(94, 129, 204, 0.02);
}
input[type=checkbox] {
  width: 1.3em;
  height: 1.3em;
  box-shadow: 0 0px 1px rgba(91, 133, 168, 0.5), 0 0px 2px rgba(91, 133, 168, 0.4), 0 0px 8px rgba(133, 171, 197, 0.02), 0 0px 16px rgba(94, 129, 204, 0.02);
}

textarea {
  resize: none;
  height: 10em;
  box-shadow: 0 0px 1px rgba(91, 133, 168, 0.5), 0 0px 2px rgba(91, 133, 168, 0.4), 0 0px 8px rgba(133, 171, 197, 0.02), 0 0px 16px rgba(94, 129, 204, 0.02);
  margin: 0;
}

select {
  padding: 0 1.3em;
  color: hsl(205, 50%, 40%);
  margin: 0;
  height: 3em;
  box-shadow: 0 0px 1px rgba(91, 133, 168, 0.5), 0 0px 2px rgba(91, 133, 168, 0.4), 0 0px 8px rgba(133, 171, 197, 0.02), 0 0px 16px rgba(94, 129, 204, 0.02), 0 0px 20px rgba(50, 100, 120, 0.1);
}

.button-primary {
  border-radius: 2em;
  padding: 0.5em 2em;
  margin: 0;
  text-align: center;
  background: linear-gradient(165deg, hsl(185, 60%, 60%) 0%, hsl(215, 60%, 40%) 100%);
  color: hsl(205, 27%, 96%);
  font-size: 1em;
  font-weight: 600;
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5em;
  transition: 0.5s;
  box-shadow: inset rgba(17, 17, 26, 0.1) 0px 0px 10px, inset rgba(17, 17, 26, 0.1) 0px 0px 80px;
}
.button-primary:hover {
  background: linear-gradient(145deg, hsl(185, 50%, 60%) 0%, hsl(215, 50%, 60%) 100%);
  color: hsl(205, 27%, 96%);
  cursor: pointer;
  box-shadow: none;
}
.button-primary:active {
  background: linear-gradient(145deg, hsl(185, 50%, 55%) 0%, hsl(215, 50%, 60%) 100%);
  cursor: pointer;
  box-shadow: inset rgba(17, 17, 26, 0.5) 0px 0px 2px, inset rgba(17, 17, 26, 0.1) 0px 0px 4px, inset rgba(17, 17, 26, 0.05) 0px 0px 8px;
  transition: 0.1s;
  transform: scale(0.99);
}

.button-secondary {
  border-radius: 2em;
  padding: 0.5em 2em;
  margin: 0;
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5em;
  transition: 0.5s;
  background: linear-gradient(165deg, hsl(185, 99%, 98%) 0%, hsl(215, 99%, 97%) 100%);
  border: 3px solid hsl(205, 50%, 60%);
  color: hsl(205, 50%, 60%);
}
.button-secondary:hover {
  background: linear-gradient(145deg, hsl(185, 70%, 95%) 0%, hsl(215, 70%, 94%) 100%);
  color: hsl(205, 50%, 60%);
  cursor: pointer;
  border: 3px solid hsl(205, 50%, 60%);
}
.button-secondary:active {
  background: linear-gradient(145deg, hsl(185, 50%, 85%) 0%, hsl(215, 50%, 90%) 100%);
  cursor: pointer;
  color: hsl(205, 50%, 60%);
  border: 3px solid hsl(205, 50%, 60%);
}

.button-stores {
  background: black;
  color: white;
  padding: 0.7em 2em;
  height: 4em;
  transition: 1s;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 400px) {
  .button-stores {
    max-width: 100%;
  }
}
@media screen and (min-width: 401px) {
  .button-stores {
    max-width: calc(50vw - 3em);
  }
}
.button-stores:hover {
  background: linear-gradient(145deg, hsl(185, 50%, 60%) 0%, hsl(215, 50%, 60%) 100%);
  cursor: pointer;
  box-shadow: inset rgba(17, 17, 26, 0.1) 0px 0px 10px, inset rgba(17, 17, 26, 0.1) 0px 0px 80px;
  transition: 0.5s;
}
.button-stores .storeImg {
  width: auto;
  max-height: 2.6em;
}

form {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.hide {
  opacity: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.fade-in {
  opacity: 1;
  visibility: visible;
  transition: opacity 1000ms ease-in-out;
}

.icon-fade-in {
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
*::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: hsl(205, 50%, 70%);
  border-radius: 20px;
}

.features {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
}
@media screen and (max-width: 431px) {
  .features {
    gap: 1em;
  }
}

@media screen and (max-width: 750px) {
  .feature {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
  }
}
@media screen and (min-width: 751px) {
  .feature {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2em;
    align-items: flex-start;
  }
}
.feature .feature-card {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
  align-items: left;
}
@media screen and (max-width: 750px) {
  .feature .feature-card {
    padding: 2em;
    width: 100%;
  }
}
@media screen and (min-width: 751px) {
  .feature .feature-card {
    padding: 3em;
    flex-grow: 1;
    height: 100%;
  }
}
.feature .feature-card h2 {
  margin-bottom: 0.5em;
}
.feature .feature-card img {
  width: 2em;
  height: 2em;
  border-radius: 0;
}
.feature .feature-card .feature-card-element {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1em;
  align-items: center;
}

.benefitCards {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  align-items: stretch;
  max-width: 1250px;
  margin: 0 auto;
}

.features .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  gap: 2em;
  height: 100%;
}
@media screen and (max-width: 750px) {
  .features .wrapper {
    flex-direction: column;
  }
}
.features .containterHeading {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  gap: 3em;
  overflow: hidden;
  padding: 1em;
  border-radius: 1.5em;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
}
@media screen and (max-width: 750px) {
  .features .containterHeading {
    padding: 0;
    border-radius: 0;
    background: none;
  }
}
.features .containterHeading .containterText {
  max-width: 625px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: 2em;
  padding: 1em 2em;
}
@media screen and (max-width: 750px) {
  .features .containterHeading .containterText {
    padding: 0;
  }
}
.features .containterHeading .iconBG {
  position: relative;
  width: 100%;
  height: 12em;
  border-radius: 0 6em 6em 0;
  opacity: 0.7;
  background: hsl(0, 0%, 100%);
  margin-left: -3em;
}
@media screen and (max-width: 750px) {
  .features .containterHeading .iconBG {
    display: none;
  }
}
.features .containterHeading .iconBG .icon {
  width: 12em;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
}
.features h3 {
  font-size: 1.5em;
  font-weight: 400;
  color: hsl(205, 80%, 20%);
}
@media screen and (max-width: 431px) {
  .features h3 {
    font-size: 1.1em;
  }
}
.features .benefitCards {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
}
.features .benefitCards .infoCard {
  width: 100%;
}
.features .bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 0.7em;
  opacity: 0.4;
}

.security {
  position: relative;
  padding: 2em;
  border-radius: 1.5em;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
}
@media screen and (max-width: 531px) {
  .security {
    padding: 0em;
    background: none;
    border-radius: 0;
  }
}
.security .containterHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.security .containterHeading .containterText {
  max-width: 625px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: 2em;
  padding: 1em 2em;
}
@media screen and (max-width: 531px) {
  .security .containterHeading .containterText {
    padding: 0em;
    padding-bottom: 1em;
    gap: 0.5em;
  }
}
.security .containterHeading img {
  width: 12em;
  height: auto;
}
@media screen and (max-width: 750px) {
  .security .containterHeading img {
    display: none;
  }
}
.security h3 {
  font-size: 1.5em;
  font-weight: 400;
  color: hsl(205, 80%, 20%);
}
@media (max-width: 600px) {
  .security .benefitCards {
    flex-direction: column;
    width: 100%;
  }
}
.security .benefitCards > div {
  width: calc(50% - 1em);
}
@media (max-width: 600px) {
  .security .benefitCards > div {
    flex-direction: column;
    width: 100%;
  }
}
.security .bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 1.5em;
  opacity: 0.8;
}

.productCards {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3em;
  align-items: center;
}
.productCards p {
  font-size: 1.1em;
  margin: 0.5em 0;
}
.productCards .intro {
  padding: 0 1em;
  max-width: 1250px;
}
.productCards .logoCardContainer {
  display: flex;
  max-width: 1250px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 2em;
}
@media screen and (max-width: 750px) {
  .productCards .logoCardContainer {
    flex-direction: column;
    gap: 0;
  }
}
.productCards .logoCardContainer a {
  z-index: 10;
}
.productCards .logoCardContainer .logo {
  width: 6em;
  height: auto;
  border-radius: 0;
  z-index: 10;
}
@media screen and (max-width: 750px) {
  .productCards .logoCardContainer .logo {
    width: 5em;
    margin-bottom: -2.8em;
  }
}
.productCards .card {
  display: flex;
  max-width: 1250px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 0em;
  position: relative;
}
.productCards .card h3 {
  font-size: 0.8em;
  font-weight: 400;
  position: absolute;
  right: 2em;
  top: 1.5em;
  opacity: 0.8;
}

.teamMember {
  display: flex;
  max-width: 1250px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 2em;
}
@media screen and (max-width: 750px) {
  .teamMember {
    gap: 1em;
  }
}
@media screen and (max-width: 531px) {
  .teamMember {
    flex-direction: column;
    align-items: flex-end;
    margin-top: -4em;
  }
}
@media screen and (max-width: 531px) {
  .teamMember .flexRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3em;
  }
}
.teamMember .spacer {
  display: none;
}
@media screen and (max-width: 531px) {
  .teamMember .spacer {
    display: block;
    height: 2em;
    width: 100%;
  }
}
.teamMember .teamPhoto {
  width: 12em;
  height: auto;
  border-radius: 50%;
}
@media screen and (max-width: 750px) {
  .teamMember .teamPhoto {
    width: 5em;
  }
}
@media screen and (max-width: 531px) {
  .teamMember .teamPhoto {
    display: none;
  }
}
.teamMember .teamPhotoSmall {
  width: 5em;
  height: auto;
  border-radius: 50%;
  margin-bottom: -3.5em;
  z-index: 10;
}
@media screen and (min-width: 530px) {
  .teamMember .teamPhotoSmall {
    display: none;
  }
}
.teamMember .card {
  flex-direction: column;
  justify-content: flex-start;
  gap: 0;
}
.teamMember .card p {
  margin: 0.5em 0;
  text-align: left;
  width: 100%;
}

.nameAndLinkedin {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}
.nameAndLinkedin .linkedin {
  width: 1.3em;
  height: auto;
  border-radius: 0.3em;
}

.trustedByContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: 2em;
  height: auto;
  width: 100%;
}

.trustedBy {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  min-width: 600px;
}
.trustedBy .photo {
  width: 5em;
  height: auto;
  border-radius: 50%;
  margin-bottom: -3.5em;
  z-index: 10;
}
.trustedBy .card {
  flex-direction: column;
  justify-content: flex-start;
  gap: 0;
  margin-left: -2.2em;
  padding: 2em 2.5em;
  height: 350px;
}
.trustedBy .card .content {
  overflow: scroll;
}
.trustedBy .card p {
  margin: 0.5em 0;
  text-align: left;
  width: 100%;
}

.clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  max-width: 1250px;
}
.client h2 {
  color: hsl(22, 100%, 45%);
}
.client p {
  margin: 0.2em 0;
  padding: 0;
}
.client .sectorImg {
  width: 15em;
  height: 15em;
  border-radius: 0.7em 0 0 0.7em;
}
@media (max-width: 600px) {
  .client .sectorImg {
    margin: 2em 0 2em 2em;
    width: 8em;
    height: 8em;
    border-radius: 1em;
  }
}
.client .card-text {
  padding: 2em;
}

.contact {
  gap: 1em;
}

.contactContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
  max-width: 1250px;
}
@media screen and (max-width: 750px) {
  .contactContainer {
    flex-direction: column;
    gap: 0em;
  }
}
.contactContainer .info {
  position: relative;
  width: 34em;
  padding: 2em;
  border-radius: 0.7em;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
}
.contactContainer .info .sectionHeader {
  font-size: 1.3em;
  line-height: 1.5em;
}
.contactContainer .info .bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 1em;
}
@media screen and (max-width: 750px) {
  .contactContainer .info {
    display: none;
  }
}
.contactContainer .emailSmallScreen {
  display: none;
}
.contactContainer .emailSmallScreen h2 {
  font-size: 1.3em;
  font-weight: 400;
  color: hsl(205, 60%, 40%);
  margin: 0;
  margin-top: -0.5em;
}
.contactContainer .emailSmallScreen p {
  font-size: 1em;
  font-weight: 400;
}
@media screen and (max-width: 750px) {
  .contactContainer .emailSmallScreen {
    display: block;
  }
}
.contactContainer .contactForm {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.02);
  width: 100%;
  max-width: calc(1250px - 34em);
}
.contactContainer .contactForm label {
  line-height: 2;
}

@media screen and (max-width: 600px) {
  .faq-main {
    padding: 0;
  }
}

.FAQHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}
.FAQHeader .askTheon {
  color: hsl(205, 50%, 70%);
  font-weight: 400;
}
@media screen and (max-width: 800px) {
  .FAQHeader {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 600px) {
  .FAQHeader {
    padding: 0 2em;
  }
}

.theon-chat-bot {
  padding: 2em;
  border-radius: 3em;
  background: linear-gradient(155deg, hsl(205, 40%, 96%) 0%, hsl(200, 45%, 90%) 100%);
  min-height: 42vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: inset 0 0 150px rgba(255, 255, 255, 0.55), 0 0 50px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 400px) {
  .theon-chat-bot {
    padding: 2em 0.5em;
  }
}
@media screen and (max-width: 600px) {
  .theon-chat-bot {
    border-radius: 0;
  }
}
.theon-chat-bot h2 {
  max-width: 100%;
  width: 100%;
  font-size: 1.1em;
  line-height: 1.5;
  color: hsl(205, 60%, 30%);
  text-align: left;
  font-weight: 400;
}
@media screen and (max-width: 431px) {
  .theon-chat-bot h2 {
    font-size: 1em;
  }
}
.theon-chat-bot .q-and-a-container {
  gap: 2em;
  max-width: 100%;
}
.theon-chat-bot .question-container {
  width: 100%;
}
.theon-chat-bot .question-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5em;
}
.theon-chat-bot .question-nav .arrow {
  cursor: pointer;
  padding: 0 1em;
}
.theon-chat-bot .question h2 {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
}
.theon-chat-bot .answer p {
  max-width: 100%;
  width: 100%;
  font-size: 1.1em;
  line-height: 1.5;
  color: hsl(205, 60%, 30%);
  text-align: left;
  font-weight: 400;
}
@media screen and (max-width: 431px) {
  .theon-chat-bot .answer p {
    font-size: 1em;
  }
}
.theon-chat-bot .search-container {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
}

.skeleton {
  width: 100%;
  margin-top: 0.5rem;
  /* mt-2 */
  margin-bottom: 1rem;
  /* mb-4 */
}
.skeleton .animate {
  animation: pulse 1.5s infinite;
}
.skeleton .space {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
}
.skeleton .line {
  height: 0.7em;
  /* h-2 */
  background-color: #c4c9d5;
  /* bg-gray-200 */
  border-radius: 0.5em;
  /* rounded */
}
.skeleton .grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  /* gap-4 */
}
.skeleton .grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  /* gap-4 */
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.suggestions {
  padding: 8vh 0;
  width: 100%;
  max-width: 100%;
}
.suggestions h3 {
  text-align: center;
  font-size: 1.5em;
  line-height: 2em;
  color: hsl(205, 50%, 60%);
  font-weight: 400;
  opacity: 0.8;
}
@media screen and (max-width: 800px) {
  .suggestions h3 {
    font-size: 1.2em;
  }
}

.suggestion-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}
.suggestion-container .suggestion {
  width: 100%;
  max-width: 100%;
  padding: 1em 2em;
  height: 100%;
  border: none;
  cursor: pointer;
  border-radius: 2em;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
}
.suggestion-container .suggestion:hover {
  background: hsl(0, 0%, 100%);
}
.suggestion-container .arrow {
  color: hsl(205, 40%, 50%);
  font-size: 2em;
  cursor: pointer;
}

.legal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
}
.legal summary {
  cursor: pointer;
  font-weight: 500;
  font-size: 1.2em;
}
.legal .legal-header h1 {
  margin: 0;
}
.legal .legal-header p {
  margin: 0.5em 0;
}
.legal article {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
}
.legal article h2 {
  margin-bottom: 0;
}
.legal article p {
  margin: 0.3em 0;
}

.errorPage {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.02);
  margin-top: 2em;
  border-radius: 3em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3em;
}
.errorPage img {
  width: auto;
  height: 8.5em;
}
.errorPage .errorText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
  width: 100%;
}
.errorPage .errorText p {
  margin: 0.1em 0;
}

.home {
  padding: 0;
  font-size: 1.1em;
  background: linear-gradient(165deg, rgb(241, 250, 248) 0%, rgb(217, 232, 240) 100%);
}

.hero-container {
  max-width: min(1250px, 100vw);
  margin: 0 auto;
  padding: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3em;
}
@media screen and (max-width: 1000px) {
  .hero-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1em;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  border-radius: 2em;
  padding: 3em;
  height: 100%;
  min-height: 50vh;
}
@media screen and (max-width: 1000px) {
  .hero {
    min-height: 30vh;
  }
}
@media screen and (max-width: 600px) {
  .hero {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 450px) {
  .hero {
    font-size: 0.7em;
  }
}
.hero h1 {
  letter-spacing: -0.02em;
  font-size: 2.5em;
  line-height: 1.5em;
  font-weight: 600;
}
@media screen and (max-width: 500px) {
  .hero h1 {
    font-size: 2em;
  }
}
.hero h2 {
  letter-spacing: -0.02em;
  font-size: 1.5em;
  line-height: 1.5em;
  color: hsl(205, 60%, 30%);
  font-weight: 300;
}
.hero .startUsingTheon {
  margin-top: 1em;
  font-size: 1.1em;
  border-radius: 2em;
  padding: 0 3em;
}

.animatedDemo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  text-align: center;
  gap: 3em;
  font-size: 1em;
  height: 100%;
  min-height: 50vh;
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .animatedDemo {
    padding: 0;
    width: calc(100vw - 6em);
  }
}
.animatedDemo h2 {
  max-width: 80vw;
  font-size: 1.1em;
  line-height: 2em;
  color: hsl(205, 60%, 30%);
  text-align: left;
  font-weight: 400;
}
@media screen and (max-width: 431px) {
  .animatedDemo h2 {
    font-size: 1em;
  }
}

.verdic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1250px;
  margin: 0 auto;
  text-align: left;
  padding: 2em;
}
.verdic .logo {
  max-width: 500px;
  width: 100%;
  height: auto;
}
.verdic h3 {
  font-size: 1.5em;
  line-height: 1.5em;
  color: hsl(205, 60%, 30%);
  font-weight: 300;
  width: 100%;
}
.verdic p {
  margin: 0;
  width: 100%;
}
.verdic .flexRow {
  gap: 2em;
}
.verdic .features {
  gap: 0.5em;
}
.verdic .verdicFeatureCards {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2em;
}
.verdic .infoCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2em;
  width: 100%;
}
.verdic .verdicCase {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
  text-align: left;
  border-radius: 3em;
}
.verdic .calculatorIcon {
  width: 5em;
  height: auto;
}
@media screen and (max-width: 650px) {
  .verdic .calculatorIcon {
    display: none;
  }
}
.verdic form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
  width: 100%;
}
.verdic form input {
  width: 4em;
  font-size: 1em;
  text-align: right;
  padding: 0.5em;
}
.verdic form label {
  color: hsl(205, 70%, 20%);
}
.verdic .calcRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2em;
}
@media screen and (max-width: 1000px) {
  .verdic .calcRow {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
}
.verdic .ROIcards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 2em;
}
.verdic .ROIcard {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 2em;
  width: 100%;
}
.verdic .ROIcard h3 {
  margin: 0;
  font-size: 1.5em;
  width: fit-content;
}
.verdic .button-primary {
  width: 22em;
}
@media screen and (max-width: 500px) {
  .verdic .button-primary {
    width: 100%;
  }
}
.verdic .waitlist {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3em;
  width: 100%;
  border-radius: 3em;
}
@media screen and (max-width: 1000px) {
  .verdic .waitlist {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
}
.verdic .waitlist .waitlistBigScreen {
  display: block;
}
@media screen and (max-width: 1000px) {
  .verdic .waitlist .waitlistBigScreen {
    display: none;
  }
}
.verdic .waitlist .waitlistSmallScreen {
  display: none;
}
@media screen and (max-width: 1000px) {
  .verdic .waitlist .waitlistSmallScreen {
    display: block;
  }
}
@media screen and (max-width: 500px) {
  .verdic .waitlist button {
    width: 100%;
  }
}
.verdic .waitListModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(207, 219, 228, 0.7);
}
.verdic .waitListModal .waitListModalContent {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  margin-top: 10em;
  padding: 3em;
  border-radius: 3em;
  max-height: calc(100% - 4em);
  width: calc(100% - 4em);
  max-width: calc(1250px - 4em);
  background: hsl(205, 27%, 96%);
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: min(50em, 100vw - 4em);
}
.verdic .waitListModal .waitListModalContent h2 {
  text-align: left;
}
.verdic .waitListModal .waitListModalContent form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5em;
  width: 100%;
}
.verdic .waitListModal .waitListModalContent form input {
  width: 100%;
  font-size: 1em;
  text-align: left;
  padding: 1em;
  margin-bottom: 1em;
}
.verdic .waitListModal .waitListModalContent form label {
  color: hsl(205, 70%, 20%);
  text-align: left;
}
.verdic .waitListModal .waitListModalContent .closeButton {
  position: absolute;
  padding: 0;
  top: 1em;
  right: 1.5em;
  width: 1em;
  height: auto;
  border-radius: 50%;
  font-size: 1.8em;
  font-weight: 100;
  cursor: pointer;
}
.verdic .waitListModal .waitListModalContent .button-primary {
  width: 100%;
}

header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  min-height: 4em;
  height: 4em;
  max-height: 4em;
  background: hsl(0, 0%, 100%);
}
header .headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 0 2em;
  max-width: 1250px;
}
header #logo {
  cursor: pointer;
  height: 1.5em;
  width: auto;
  transition: 0.5s;
}
header #logo:hover {
  transition: 0.5s;
}
header #logo:active {
  transform: scale(0.95);
  transition: 0.1s;
}
header .hamburger {
  display: none;
  cursor: pointer;
  margin-top: -0.4em;
}
header .hamburger .line {
  width: 1.5em;
  height: 0.1em;
  background-color: hsl(205, 50%, 40%);
  margin: 0.3em 0;
}
header .hamburger:hover .line {
  background-color: hsl(205, 50%, 70%);
}
header .hamburger:active .line {
  background-color: hsl(205, 50%, 40%);
}
@media screen and (max-width: 849px) {
  header .hamburger {
    display: block;
  }
}
header .menuItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2em;
}
@media screen and (max-width: 1000px) {
  header .menuItems {
    gap: 1em;
  }
}
header .menuItemsSmall {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2em;
  background-color: hsl(0, 0%, 100%);
  position: absolute;
  top: 3em;
  right: 2em;
  gap: 2em;
  border-radius: 1.5em;
  box-shadow: 0 0px 1px rgba(91, 133, 168, 0.5), 0 0px 2px rgba(91, 133, 168, 0.4), 0 0px 8px rgba(133, 171, 197, 0.02), 0 0px 16px rgba(94, 129, 204, 0.02), 0 0px 20px rgba(50, 100, 120, 0.1);
}
header .menuItem {
  font-family: Inter, Mona Sans, poppins, sans-serif;
  cursor: pointer;
  font-size: 1.1em;
  color: hsl(205, 90%, 20%);
  font-weight: 500;
  opacity: 0.8;
  padding: 0;
}
header .menuItem:hover {
  opacity: 1;
}
header .startUsingTheon {
  border-radius: 3em;
  padding: 0;
  min-width: 11em;
  height: 2.5em;
  font-weight: 500;
}
font-wi header .startUsingTheon:hover {
  scale: 1.05;
  transition: 0.5s;
}
header .startUsingTheon:active {
  opacity: 1;
}
header .language {
  display: flex;
  flex-direction: row;
  z-index: 11;
  transition: 0.5s;
}
header .language .flag {
  width: auto;
  height: 1.5em;
  cursor: pointer;
  opacity: 1;
}
header .language .flag:hover {
  opacity: 0.7;
  transform: scale(1.1);
}
header .language .flagPopup {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.02);
  z-index: 12;
  position: absolute;
  right: 0em;
  top: 0em;
  padding: 2em;
  opacity: 1;
  background-color: rgb(240, 240, 240);
  animation-duration: 0.5s;
}
header .language .flagPopup .flag {
  display: block;
  width: auto;
  height: 3em;
  cursor: pointer;
  animation-duration: 0.5s;
  opacity: 1;
}
header .language .flagPopup .flag:hover {
  transition: 0.5s;
  opacity: 0.7;
  transform: none;
}
header .language .flagPopup .flagActive {
  cursor: default;
  opacity: 0.3;
}
header .language .flagPopup .flagActive:hover {
  opacity: 0.3;
  transform: none;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(min(1250px, 100vw) - 4em);
  margin: 0 auto;
  bottom: 0;
  padding: 1.5em 2em;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  z-index: 10;
  border-radius: 1em 1em 0 0;
}
@media screen and (min-width: 751px) {
  footer {
    align-items: center;
  }
}
footer nav {
  display: flex;
  color: hsl(205, 50%, 70%);
}
@media screen and (max-width: 750px) {
  footer nav {
    flex-direction: column;
    gap: 0.5em;
  }
}
@media screen and (min-width: 751px) {
  footer nav {
    flex-direction: row;
    gap: 2em;
  }
}
footer a {
  font-weight: 400;
  opacity: 0.7;
}
footer a:hover {
  opacity: 1;
}
footer a:active {
  opacity: 1;
  transform: scale(0.95);
}
footer .rights {
  text-align: right;
  margin: 0;
}

.card {
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5em;
}
.card img {
  width: 5em;
  height: 5em;
}
.card .card-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5em;
}

.infoCard {
  align-items: center;
  width: 30em;
  padding: 1.5em 2em;
}
.infoCard .card-text h2 {
  font-size: 1.2em;
  color: hsl(205, 80%, 20%);
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.infoCard .card-text p {
  font-size: 1.1em;
  margin: 0;
  padding: 0;
}
.infoCard .imgBig {
  width: 4em;
  height: auto;
}
@media screen and (max-width: 530px) {
  .infoCard .imgBig {
    display: none;
  }
}
.infoCard .imgSmall {
  height: 1.5em;
  width: auto;
}
@media screen and (min-width: 531px) {
  .infoCard .imgSmall {
    display: none;
  }
}
.infoCard .flexRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}

.q-and-a-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 20em;
  gap: 4em;
}
.q-and-a-container .question {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.q-and-a-container .question h2 {
  background-color: white;
  border-radius: 2em;
  padding: 1em 2em;
  max-width: 70%;
  height: 100%;
}
@media screen and (max-width: 431px) {
  .q-and-a-container .question h2 {
    max-width: 90%;
  }
}
.q-and-a-container .answer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.search-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1em;
  padding: 1em;
  width: 100%;
  background-color: white;
  border-radius: 2em;
  padding: 0.5em 0.5em 0.5em 2em;
}
.search-container .search-input {
  width: 100%;
  min-height: 3em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search-container .search-button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  width: 3em;
  padding: 0.7em;
}
.search-container .search-button svg {
  border-radius: 50%;
}
.search-container input {
  border: none;
  box-shadow: none;
  padding: 0;
  font-size: 1.1em;
  line-height: 2em;
  color: hsl(205, 60%, 30%);
  text-align: left;
  font-weight: 400;
}

.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(207, 219, 228, 0.7);
}
.loadingContainer .loadingAnimation {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  margin-top: 10em;
  padding: 3em;
  border-radius: 3em;
  max-height: calc(100% - 4em);
  width: calc(100% - 4em);
  max-width: calc(1250px - 4em);
  background: hsl(205, 27%, 96%);
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.05);
  margin-top: 30vh;
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m:
  	conic-gradient(#0000 10%, #000),
  	linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(207, 219, 228, 0.7);
  z-index: 10;
}
.alert .alert-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  margin-top: 10em;
  padding: 3em;
  border-radius: 3em;
  max-height: calc(100% - 4em);
  width: calc(100% - 4em);
  max-width: calc(1250px - 4em);
  background: hsl(205, 27%, 96%);
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.05);
  border-radius: 0.7em;
  padding: 2em;
  text-align: left;
  transition: 0.5s;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.02);
  border-radius: 2em;
  padding: 2em;
  top: 20vh;
  opacity: 1;
  background: hsl(205, 27%, 96%);
  max-width: 30em;
}
.alert .alert-content button {
  margin-top: 1.5em;
}
.alert .success h2 {
  color: hsl(var(--hue-correct), var(--saturation-lower), var(--low));
}
.alert .success button {
  background: linear-gradient(185deg, hsl(175, 40%, 55%) 0%, hsl(165, 40%, 60%) 100%);
}
.alert .error h2 {
  color: hsl(var(--hue-incorrect), var(--saturation-low), var(--low));
}
.alert .error button {
  background: linear-gradient(165deg, hsl(355, 55%, 55%) 0%, hsl(5, 55%, 40%) 100%);
  color: rgb(var(--white), var(--white), var(--white));
}

