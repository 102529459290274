////////////////////
//      HOME      //
////////////////////

.home {
	padding: 0;
	font-size: 1.1em;
	background: linear-gradient(165deg, rgb(241, 250, 248) 0%, rgb(217, 232, 240) 100%);
}

.hero-container {
	max-width: calc(min($maxWidth, 100vw));
	margin: 0 auto;
	padding: 2em;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 3em;

	@media screen and (max-width: 1000px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.hero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 1em;

	background: $gradient-white;
	border-radius: 2em;
	padding: 3em;

	height: 100%;
	min-height: 50vh;

	@media screen and (max-width: 1000px) {
		min-height: 30vh;
	}

	@media screen and (max-width: 600px) {
		font-size: 0.9em;
	}

	@media screen and (max-width: 450px) {
		font-size: 0.7em;
	}

	h1 {
		letter-spacing: -0.02em;
		font-size: 2.5em;
		line-height: 1.5em;
		font-weight: 600;

		@media screen and (max-width: 500px) {
			font-size: 2em;
		}
	}

	h2 {
		letter-spacing: -0.02em;
		font-size: 1.5em;
		line-height: 1.5em;
		color: hsl($hue-primary, 60%, 30%);
		font-weight: 300;
	}


	.startUsingTheon {
		margin-top: 1em;
		font-size: 1.1em;
		border-radius: 2em;
		padding: 0 3em;
	}

}


.animatedDemo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	text-align: center;
	gap: 3em;
	font-size: 1em;
	height: 100%;
	min-height: 50vh;
	width: 100%;

	@media screen and (max-width: 1000px) {
		padding: 0;
		width: calc(100vw - 6em);
	}


	h2 {
		max-width: 80vw;
		font-size: 1.1em;
		line-height: 2em;
		color: hsl($hue-primary, 60%, 30%);
		text-align: left;
		font-weight: 400;

		@media screen and (max-width: 431px) {
			font-size: 1em;
		}
	}
}