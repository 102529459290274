.features {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 2em;

	@media screen and (max-width: 431px) {
		gap: 1em;
	}
}

.feature {
	@media screen and (max-width: $maxWidthMobile) {
		display: flex;
		flex-wrap: wrap;
		gap: 2em;
	}

	@media screen and (min-width: $minWidthWeb) {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		gap: 2em;
		align-items: flex-start;
	}

	.feature-card {
		@include basicCard();
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 1em;
		align-items: left;

		@media screen and (max-width: $maxWidthMobile) {
			padding: 2em;
			width: 100%;
		}

		@media screen and (min-width: $minWidthWeb) {
			padding: 3em;
			flex-grow: 1;
			height: 100%;
		}

		h2 {
			margin-bottom: 0.5em;
		}

		img {
			width: 2em;
			height: 2em;
			border-radius: 0;
		}

		.feature-card-element {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			gap: 1em;
			align-items: center;
		}
	}
}

/////////////////
/// BENEFITS ////
/////////////////
.benefitCards {
	display: flex;
	flex-wrap: wrap;
	gap: 2em;
	align-items: stretch;
	max-width: $maxWidth;
	margin: 0 auto;
}


.features {
	.wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;
		gap: 2em;
		height: 100%;

		@media screen and (max-width: $maxWidthMobile) {
			flex-direction: column;
		}
	}

	.containterHeading {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		flex-grow: 1;
		gap: 3em;
		overflow: hidden;
		padding: 1em;
		border-radius: 1.5em;
		background: $gradient-theon-bg;

		@media screen and (max-width: $maxWidthMobile) {
			padding: 0;
			border-radius: 0;
			background: none;
		}

		.containterText {
			max-width: calc($maxWidth / 2);
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: left;
			gap: 2em;
			padding: 1em 2em;

			@media screen and (max-width: $maxWidthMobile) {
				padding: 0;
			}


		}

		.iconBG {
			position: relative;
			width: 100%;
			height: 12em;
			border-radius: 0 6em 6em 0;
			opacity: 0.7;
			background: hsl(0, 0%, 100%);
			margin-left: -3em;

			@media screen and (max-width: $maxWidthMobile) {
				display: none;
			}

			.icon {
				width: 12em;
				height: auto;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}

	h3 {
		font-size: 1.5em;
		font-weight: 400;
		color: hsl($hue-primary, 80%, 20%);

		@media screen and (max-width: 431px) {
			font-size: 1.1em;
		}

	}

	.benefitCards {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 2em;

		.infoCard {
			width: 100%;
		}
	}

	.bgImg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 0.7em;
		opacity: 0.4;
	}
}

/////////////////
/// security ////
/////////////////
.security {
	position: relative;
	padding: 2em;
	border-radius: 1.5em;
	background: $gradient-theon-bg;

	@media screen and (max-width: 531px) {
		padding: 0em;
		background: none;
		border-radius: 0;
	}

	.containterHeading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.containterText {
			max-width: calc($maxWidth / 2);
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: left;
			gap: 2em;
			padding: 1em 2em;

			@media screen and (max-width: 531px) {
				padding: 0em;
				padding-bottom: 1em;
				gap: 0.5em;
			}
		}

		img {
			width: 12em;
			height: auto;

			@media screen and (max-width: $maxWidthMobile) {
				display: none;
			}
		}
	}

	h3 {
		font-size: 1.5em;
		font-weight: 400;
		color: hsl($hue-primary, 80%, 20%);
	}

	.benefitCards {
		@media (max-width: 600px) {
			flex-direction: column;
			width: 100%;
		}
	}

	.benefitCards>div {
		width: calc(100% / 2 - 1em);

		@media (max-width: 600px) {
			flex-direction: column;
			width: 100%;
		}
	}

	.bgImg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 1.5em;
		opacity: 0.8;
	}
}

/////////////////
/// PRODUCTS ////
/////////////////
.productCards {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 3em;
	align-items: center;

	p {
		font-size: 1.1em;
		margin: 0.5em 0;
	}


	.intro {
		padding: 0 1em;
		max-width: $maxWidth;
	}

	.logoCardContainer {
		display: flex;
		max-width: $maxWidth;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		text-align: left;
		gap: 2em;

		@media screen and (max-width: $maxWidthMobile) {
			flex-direction: column;
			gap: 0;
		}

		a {
			z-index: 10;
		}

		.logo {
			width: 6em;
			height: auto;
			border-radius: 0;
			z-index: 10;

			@media screen and (max-width: $maxWidthMobile) {
				width: 5em;
				margin-bottom: -2.8em;
			}

		}
	}

	.card {
		display: flex;
		max-width: $maxWidth;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		text-align: left;
		gap: 0em;
		position: relative;

		h3 {
			font-size: 0.8em;
			font-weight: 400;
			position: absolute;
			right: 2em;
			top: 1.5em;
			opacity: 0.8;
		}
	}
}

/////////////////
/// TEAM ////
/////////////////

.teamMember {
	display: flex;
	max-width: $maxWidth;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	gap: 2em;

	@media screen and (max-width: $maxWidthMobile) {
		gap: 1em;
	}

	@media screen and (max-width: 531px) {
		flex-direction: column;
		align-items: flex-end;
		margin-top: -4em;
	}

	.flexRow {
		@media screen and (max-width: 531px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 0.3em;
		}
	}

	.spacer {
		display: none;

		@media screen and (max-width: 531px) {
			display: block;
			height: 2em;
			width: 100%;
		}
	}


	.teamPhoto {
		width: 12em;
		height: auto;
		border-radius: 50%;

		@media screen and (max-width: $maxWidthMobile) {
			width: 5em;
		}

		@media screen and (max-width: 531px) {
			display: none;
		}
	}

	.teamPhotoSmall {
		width: 5em;
		height: auto;
		border-radius: 50%;
		margin-bottom: -3.5em;
		z-index: 10;

		@media screen and (min-width: 530px) {
			display: none;
		}

	}


	.card {
		flex-direction: column;
		justify-content: flex-start;
		gap: 0;

		p {
			margin: 0.5em 0;
			text-align: left;
			width: 100%;
		}


	}

}

/////////////////
/// TRUSTED BY ////
/////////////////
.nameAndLinkedin {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5em;

	.linkedin {
		width: 1.3em;
		height: auto;
		border-radius: 0.3em;
	}
}

.trustedByContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	gap: 2em;
	height: auto;
	width: 100%;
}

.trustedBy {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	min-width: 600px;

	.photo {
		width: 5em;
		height: auto;
		border-radius: 50%;
		margin-bottom: -3.5em;
		z-index: 10;
	}

	.card {
		flex-direction: column;
		justify-content: flex-start;
		gap: 0;
		margin-left: -2.2em;
		padding: 2em 2.5em;
		height: 350px;

		.content {
			overflow: scroll;
		}

		p {
			margin: 0.5em 0;
			text-align: left;
			width: 100%;
		}


	}

}

/////////////////
/// CLIENTS  ////
/////////////////
.clients {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.client {
	@include basicCard();
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	padding: 0;
	max-width: $maxWidth;

	h2 {
		color: $color-highlight;
	}

	p {
		margin: 0.2em 0;
		padding: 0;
	}

	.sectorImg {
		width: 15em;
		height: 15em;
		border-radius: 0.7em 0 0 0.7em;

		@media (max-width: 600px) {
			margin: 2em 0 2em 2em;
			width: 8em;
			height: 8em;
			border-radius: 1em;
		}
	}

	.card-text {
		padding: 2em;
	}
}



/////////////////
/// CONTACT  ////
/////////////////
.contact {
	gap: 1em;
}

.contactContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 2em;
	max-width: $maxWidth;

	@media screen and (max-width: $maxWidthMobile) {
		flex-direction: column;
		gap: 0em;
	}

	.info {
		position: relative;
		width: 34em;
		padding: 2em;
		border-radius: 0.7em;
		background: $gradient-semi_transparant_white;

		.sectionHeader {
			font-size: 1.3em;
			line-height: 1.5em;
			// color: $color-off_white;
			// text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		}


		.bgImg {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			border-radius: 1em;
			// opacity: 0.9;
		}

		@media screen and (max-width: $maxWidthMobile) {
			display: none;
		}
	}


	.emailSmallScreen {
		display: none;

		h2 {
			font-size: 1.3em;
			font-weight: 400;
			color: hsl($hue-primary, 60%, 40%);
			margin: 0;
			margin-top: -0.5em;
		}

		p {
			font-size: 1em;
			font-weight: 400;
		}

		@media screen and (max-width: $maxWidthMobile) {
			display: block;
		}
	}



	.contactForm {
		@include basicCard();
		width: 100%;
		max-width: calc($maxWidth - 34em);

		label {
			line-height: 2;
		}


	}
}

////////////////////
/// FAQ SECTION ////
////////////////////

.faq-main {
	@media screen and (max-width: 600px) {
		padding: 0;
	}
}

.FAQHeader {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 1em;

	.askTheon {
		color: hsl($hue-primary, 50%, 70%);
		font-weight: 400;
	}

	@media screen and (max-width: 800px) {
		flex-direction: column;
		align-items: flex-start;
	}

	@media screen and (max-width: 600px) {
		padding: 0 2em;
	}
}

.theon-chat-bot {
	padding: 2em;
	border-radius: 3em;
	background: linear-gradient(155deg,
			hsl($hue-primary, 40%, 96%) 0%,
			hsl($hue-primary - 5, 45%, 90%) 100%);
	min-height: 42vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	box-shadow: inset 0 0 150px rgba(255, 255, 255, 0.55),
		0 0 50px rgba(0, 0, 0, 0.05);

	@media screen and (max-width: 400px) {
		padding: 2em 0.5em;
	}

	@media screen and (max-width: 600px) {
		border-radius: 0;
	}

	h2 {
		max-width: 100%;
		width: 100%;
		font-size: 1.1em;
		line-height: 1.5;
		color: hsl($hue-primary, 60%, 30%);
		text-align: left;
		font-weight: 400;

		@media screen and (max-width: 431px) {
			font-size: 1em;
		}
	}

	.q-and-a-container {
		gap: 2em;
		max-width: 100%;
	}

	.question-container {
		width: 100%;
	}

	.question-nav {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 0.5em;

		.arrow {
			cursor: pointer;
			padding: 0 1em;
		}
	}

	.question {
		h2 {
			box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
		}
	}

	.answer {
		p {
			max-width: 100%;
			width: 100%;
			font-size: 1.1em;
			line-height: 1.5;
			color: hsl($hue-primary, 60%, 30%);
			text-align: left;
			font-weight: 400;

			@media screen and (max-width: 431px) {
				font-size: 1em;
			}
		}
	}

	.search-container {
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
	}

}



.skeleton {
	width: 100%;
	margin-top: 0.5rem;
	/* mt-2 */
	margin-bottom: 1rem;
	/* mb-4 */

	.animate {
		animation: pulse 1.5s infinite;
	}

	.space {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 1em;
	}

	.line {
		height: 0.7em;
		/* h-2 */
		background-color: #c4c9d5;
		/* bg-gray-200 */
		border-radius: 0.5em;
		/* rounded */
	}

	.grid-3 {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1rem;
		/* gap-4 */
	}

	.grid-4 {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;
		/* gap-4 */
	}


	@keyframes pulse {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0.6;
		}

		100% {
			opacity: 1;
		}
	}
}

.suggestions {
	padding: 8vh 0;
	width: 100%;
	max-width: 100%;

	h3 {
		text-align: center;
		font-size: 1.5em;
		line-height: 2em;
		color: hsl($hue-primary, 50%, 60%);
		font-weight: 400;
		opacity: 0.8;

		@media screen and (max-width: 800px) {
			font-size: 1.2em;
		}
	}
}

.suggestion-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 1em;

	.suggestion {
		width: 100%;
		max-width: 100%;
		padding: 1em 2em;
		height: 100%;
		border: none;
		cursor: pointer;
		border-radius: 2em;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
		background: $gradient-semi_transparant_white;

		&:hover {
			background: $color-white;
		}
	}


	.arrow {
		color: hsl($hue-primary, 40%, 50%);
		font-size: 2em;
		cursor: pointer;
	}

}

//////////////
/// LEGAL ////
//////////////
.legal {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 1em;

	summary {
		cursor: pointer;
		font-weight: 500;
		font-size: 1.2em;
	}

	.legal-header {
		h1 {
			margin: 0;
		}

		p {
			margin: 0.5em 0;
		}
	}

	article {
		@include basicCard();
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: left;

		h2 {
			margin-bottom: 0;
		}

		p {
			margin: 0.3em 0;
		}
	}
}

////////////////
// ERROR PAGE //
////////////////
.errorPage {
	@include basicCard();
	margin-top: 2em;
	border-radius: 3em;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 3em;

	img {
		width: auto;
		height: 8.5em;
	}

	.errorText {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 2em;
		width: 100%;

		p {
			margin: 0.1em 0;
		}
	}
}