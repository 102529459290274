////////////////
/// VERDIC ////
////////////////
.verdic {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: $maxWidth;
	margin: 0 auto;
	text-align: left;
	padding: 2em;

	.logo {
		max-width: 500px;
		width: 100%;
		height: auto;
	}

	h3 {
		font-size: 1.5em;
		line-height: 1.5em;
		color: hsl($hue-primary, 60%, 30%);
		font-weight: 300;
		width: 100%;
	}

	p {
		margin: 0;
		width: 100%;
	}

	.flexRow {
		gap: 2em;
	}

	.features {
		gap: 0.5em;
	}

	.verdicFeatureCards {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 2em;
	}

	.infoCard {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		gap: 2em;
		width: 100%;
	}

	.verdicCase {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 1em;
		text-align: left;
		border-radius: 3em;
	}

	.calculatorIcon {
		width: 5em;
		height: auto;

		@media screen and (max-width: 650px) {
			display: none;
		}
	}


	form {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 1em;
		width: 100%;

		input {
			width: 4em;
			font-size: 1em;
			text-align: right;
			padding: 0.5em;
		}

		label {
			color: hsl($hue-primary, $saturation-high, 20%);
		}
	}

	.calcRow {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 2em;

		@media screen and (max-width: 1000px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1em;
		}
	}

	.ROIcards {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		gap: 2em;
	}

	.ROIcard {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		gap: 2em;
		// background-color: hsl(150, 60%, 95%);
		// border: 2px solid hsl(150, 20%, 85%);
		// padding: 2em 0;
		// border-radius: 2em;
		width: 100%;

		h3 {
			margin: 0;
			font-size: 1.5em;
			width: fit-content;
		}

	}

	.button-primary {
		width: 22em;

		@media screen and (max-width: 500px) {
			width: 100%;
		}
	}

	.waitlist {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 3em;
		width: 100%;
		border-radius: 3em;

		@media screen and (max-width: 1000px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1em;
		}

		.waitlistBigScreen {
			display: block;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.waitlistSmallScreen {
			display: none;

			@media screen and (max-width: 1000px) {
				display: block;
			}
		}

		@media screen and (max-width: 500px) {
			button {
				width: 100%;
			}
		}

	}

	.waitListModal {
		@include modalBase();

		.waitListModalContent {
			@include modalContent();

			width: 100%;
			max-width: calc(min(50em, 100vw - 4em));

			h2 {
				text-align: left;
			}

			form {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				gap: 0.5em;
				width: 100%;

				input {
					width: 100%;
					font-size: 1em;
					text-align: left;
					padding: 1em;
					margin-bottom: 1em;
				}

				label {
					color: hsl($hue-primary, $saturation-high, 20%);
					text-align: left;
				}
			}

			.closeButton {
				position: absolute;
				padding: 0;
				top: 1em;
				right: 1.5em;
				width: 1em;
				height: auto;
				border-radius: 50%;
				font-size: 1.8em;
				font-weight: 100;
				cursor: pointer;

			}

			.button-primary {
				width: 100%;
			}
		}
	}
}