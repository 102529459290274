*,
*:before,
*:after {
	box-sizing: border-box;
}

*:focus {
	border: none;
	outline: none;
}

*:active {
	outline: none;
}

* {
	-webkit-tap-highlight-color: transparent;
	caret-color: transparent;
}

html {
	background: linear-gradient(165deg, rgb(245, 253, 253) 0%, rgb(217, 232, 240) 100%);
}

html,
body {
	margin: 0;
	padding: 0;
	line-height: 1.5;
	font-family: Inter, Mona Sans, poppins, sans-serif;
	// user-select: none;
}

.App {
	@include flexRowSpaceBetween();
	font-weight: 400;
	color: $color-primary;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	min-height: 100vh;
}

main {
	flex: 1;
	padding: 0 2em;
	z-index: 0;

	display: flex;
	flex-direction: column;
	gap: 2em;

	h4 {
		font-size: 0.7em;
		padding-bottom: 0.2em;
		font-weight: normal;
	}
}

.section {
	margin: 0 auto;
	max-width: $maxWidth;
	padding-top: $headerHeight;
	padding-bottom: 5em;

	@media screen and (max-width: 500px) {
		padding-top: 1em;
		padding-bottom: 3em;
	}

	// min-height: 100vh;
}

.sectionHeader {
	font-size: 2.7em;
	font-weight: 600;
	color: hsl($hue-primary, 60%, 30%);
	text-align: left;

	@media screen and (max-width: 500px) {
		font-size: 1.7em;
	}
}

h1,
h2,
h3,
h4,
h5,
p,
table {
	margin: 0;
	padding: 0;
}

p {
	margin: 1em 0;
	color: hsl($hue-primary, $saturation-high, 20%);
}

table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

table,
th,
td {
	border: none;
}

th {
	padding: 0;
	text-align: left;
	color: $color-primary;
	font-weight: 500;
}

sup {
	font-size: 0.5em;
	line-height: 1em;
	margin-top: -0.5em;
}

h1 {
	font-size: 1.3em;
	letter-spacing: 0.1rem;
	font-weight: 400;
	color: $color-primary;

	@media screen and (max-width: 500px) {
		font-size: 1.1em;
	}
}

h2 {
	font-size: 1.1em;
	line-height: 1.1;
	font-weight: 500;
	color: hsl($hue-primary, $saturation-low, $brightness-medium);

	@media screen and (max-width: 500px) {
		font-size: 0.9em;
	}
}

h3 {
	font-size: 1.2em;
	color: hsl($hue-primary, $saturation-low, $brightness-low);

	@media screen and (max-width: 500px) {
		font-size: 0.9em;
	}
}

h5 {
	font-size: 0.5em;
	font-weight: 600;
}

a,
.link {
	text-decoration: none;
	color: $color-link;
	font-weight: 500;
	transition: $transition-medium;
	cursor: pointer;

	&:hover {
		color: $color-hover;
	}
}

.error {
	color: hsl($hue-incorrect - 10, $saturation-medium, $brightness-medium);
}

details>summary {
	list-style: none;
}

details>summary::-webkit-details-marker {
	display: none;
}

.flexRow {
	@include flexRowSpaceBetween();
	align-items: center;
	width: 100%;
}

//////////////////////
//  INPUT & BUTTON  //
//////////////////////
input,
button,
select,
textarea {
	font-family: Inter, Mona Sans, poppins, sans-serif;
	font-size: 1em;
	width: 100%;
	padding: 1em 1.2em 1em 1.2em;
	border-radius: 0.5em;
	border: none;
	background: none;
	caret-color: black;

	&:focus {
		border: none;
		outline: none;
	}
}

input {
	margin: 0;
	height: 3em;
	caret-color: black;
	box-shadow: $light-box-shadow;

	&[type="checkbox"] {
		width: 1.3em;
		height: 1.3em;
		box-shadow: $light-box-shadow;
	}
}

textarea {
	resize: none;
	height: 10em;
	box-shadow: $light-box-shadow;
	margin: 0;
}

select {
	padding: 0 1.3em;
	color: $color-primary;
	margin: 0;
	height: 3em;
	box-shadow: $standard-box-shadow;
}

.button-primary {
	border-radius: 2em;
	padding: 0.5em 2em;
	margin: 0;
	text-align: center;
	background: $gradient-button;
	color: $color-off_white;
	font-size: 1em;
	font-weight: 600;
	vertical-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.5em;
	transition: $transition-medium;
	box-shadow:
		inset rgba(17, 17, 26, 0.1) 0px 0px 10px,
		inset rgba(17, 17, 26, 0.1) 0px 0px 80px;

	&:hover {
		background: $gradient-button_hover;
		color: $color-off_white;
		cursor: pointer;
		box-shadow: none;
	}

	&:active {
		background: $gradient-button_active;
		cursor: pointer;
		box-shadow: $complex-inset-box-shadow;
		transition: $transition-fast;
		transform: scale(0.99);
	}
}



.button-secondary {
	border-radius: 2em;
	padding: 0.5em 2em;
	margin: 0;
	text-align: center;
	font-size: 1em;
	font-weight: 600;
	vertical-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.5em;
	transition: $transition-medium;

	background: $gradient-button-secondary;
	border: 3px solid hsl($hue-primary, $saturation-low + 10, $brightness-medium);
	color: hsl($hue-primary, $saturation-low + 10, $brightness-medium);

	&:hover {
		background: $gradient-button_hover-secondary;
		color: hsl($hue-primary, $saturation-low + 10, $brightness-medium);
		cursor: pointer;
		border: 3px solid hsl($hue-primary, $saturation-low + 10, $brightness-medium);
	}

	&:active {
		background: $gradient-button_active-secondary;
		cursor: pointer;
		color: hsl($hue-primary, $saturation-low + 10, $brightness-medium);
		border: 3px solid hsl($hue-primary, $saturation-low + 10, $brightness-medium);
	}


}

.button-stores {
	background: black;
	color: white;
	padding: 0.7em 2em;
	height: 4em;
	transition: $transition-slow;
	display: flex;
	justify-content: center;

	@media screen and (max-width: 400px) {
		max-width: 100%;
	}

	@media screen and (min-width: 401px) {
		max-width: calc((100vw / 2) - 3em);
	}

	&:hover {
		background: $gradient-button_hover;
		cursor: pointer;
		box-shadow:
			inset rgba(17, 17, 26, 0.1) 0px 0px 10px,
			inset rgba(17, 17, 26, 0.1) 0px 0px 80px;
		transition: $transition-medium;
	}

	.storeImg {
		width: auto;
		max-height: 2.6em;
	}
}

form {
	display: flex;
	flex-direction: column;
	gap: 2em;
}

.hide {
	opacity: 0;
}

.disabled {
	pointer-events: none;
	opacity: 0.3;
}


.hidden {
	opacity: 0;
	visibility: hidden;
}

.fade-in {
	opacity: 1;
	visibility: visible;
	transition: opacity 1000ms ease-in-out;
}

.icon-fade-in {
	animation: fadeIn 1s ease forwards; // Adjust duration and easing as needed
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(30px); // Optional: adds a slight upward movement
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

///////////////
// SCROLLBAR //
///////////////
// Works on Firefox
// * {
// 	scrollbar-width: thin;
// 	scrollbar-color: $color-secondary $color-secondary;
// }

*::-webkit-scrollbar {
	width: 8px;
	height: 12px;
}

*::-webkit-scrollbar-thumb {
	background-color: $color-secondary;
	border-radius: 20px;
}