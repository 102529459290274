:root {
	--hue-primary: 220;
	--hue-link: 195;
	--hue-correct: 170;
	--hue-incorrect: 0;

	--saturation-bg: 35%;

	--saturation-lowest: 20%;
	--saturation-lower: 30%;
	--saturation-low: 40%;
	--saturation-medium-low: 50%;
	--saturation-medium: 60%;
	--saturation-medium-high: 70%;
	--saturation-high: 70%;
	--saturation-highest: 90%;
	--saturation-text: 35%;

	--lower: 35%;
	--low: 40%;
	--medium-low: 50%;
	--medium: 60%;
	--medium-high: 70%;
	--high: 80%;
	--highest: 90%;
	--brightness-text: 45%;

	--modal-background: rgba(207, 210, 228, 0.7);
	--card-r: 242;
	--card-g: 234;
	--card-b: 248;
	--card-white-r: 255;
	--card-white-g: 255;
	--card-white-b: 255;
	--white: 255;

	--image-opacity: 1;
	--opacity-unclickable: 0.5;
}

// .dark-mode {
// 	--hue-primary: 210;
// 	// --hue-link: 195;
// 	// --hue-correct: 170;
// 	// --hue-incorrect: 0;
// 	--saturation-bg: 20%;

// 	--saturation-lowest: 10%;
// 	--saturation-lower: 20%;
// 	--saturation-low: 30%;
// 	--saturation-medium-low: 40%;
// 	--saturation-medium: 50%;
// 	--saturation-medium-high: 55%;
// 	--saturation-high: 60%;
// 	--saturation-highest: 90%;
// 	--saturation-text: 25%;

// 	--lower: 55%;
// 	--low: 50%;
// 	--medium-low: 50%;
// 	--medium: 45%;
// 	--medium-high: 40%;
// 	--high: 30%;
// 	--highest: 10%;
// 	--brightness-text: 70%;

// 	--modal-background: rgba(0, 0, 0, 0.5);
// 	--card-r: 40;
// 	--card-g: 45;
// 	--card-b: 47;
// 	--card-white-r: 40;
// 	--card-white-g: 45;
// 	--card-white-b: 50;
// 	--white: 210;
// 	--image-opacity: 0.6;
// 	--opacity-unclickable: 0.2;
// }