////////////
// HEADER //
////////////
header {
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 1000;

	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	min-height: $headerHeight;
	height: $headerHeight;
	max-height: $headerHeight;

	background: $color-white;

	.headerContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100vw;
		padding: 0 2em;
		max-width: $maxWidth;
	}

	#logo {
		cursor: pointer;
		height: 1.5em;
		width: auto;
		transition: $transition-medium;

		&:hover {
			transition: $transition-medium;
		}

		&:active {
			transform: scale(0.95);
			transition: $transition-fast;
		}
	}

	.hamburger {
		display: none;
		cursor: pointer;
		margin-top: -0.4em;

		.line {
			width: 1.5em;
			height: 0.1em;
			background-color: $color-primary;
			margin: 0.3em 0;
		}

		&:hover {
			.line {
				background-color: $color-secondary;
			}
		}

		&:active {
			.line {
				background-color: $color-primary;
			}
		}

		@media screen and (max-width: 849px) {
			display: block;
		}
	}

	.menuItems {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		gap: 2em;

		@media screen and (max-width: 1000px) {
			gap: 1em;
		}


	}

	.menuItemsSmall {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		padding: 2em;
		background-color: $color-white;
		position: absolute;
		top: 3em;
		right: 2em;
		gap: 2em;
		border-radius: 1.5em;
		box-shadow: $standard-box-shadow;
	}

	.menuItem {
		font-family: Inter, Mona Sans, poppins, sans-serif;
		cursor: pointer;
		font-size: 1.1em;
		color: hsl($hue-primary, 90%, 20%);
		font-weight: 500;
		opacity: 0.8;
		padding: 0;

		&:hover {
			opacity: 1;
		}

	}


	.startUsingTheon {
		border-radius: 3em;
		padding: 0;
		min-width: 11em;
		height: 2.5em;
		font-weight: 500;

		font-wi &:hover {
			scale: 1.05;
			transition: $transition-medium;
		}

		&:active {
			opacity: 1;
		}


	}

	.language {
		display: flex;
		flex-direction: row;
		z-index: 11;
		transition: $transition-medium;

		.flag {
			width: auto;
			height: 1.5em;
			cursor: pointer;
			opacity: 1;

			&:hover {
				opacity: 0.7;
				transform: scale(1.1);
			}
		}

		.flagPopup {
			@include basicCard();
			z-index: 12;
			position: absolute;
			right: 0em;
			top: 0em;
			padding: 2em;
			opacity: 1;
			background-color: rgba(240, 240, 240, 1);
			animation-duration: $transition-medium;

			.flag {
				display: block;
				width: auto;
				height: 3em;
				cursor: pointer;
				animation-duration: $transition-medium;
				opacity: 1;

				&:hover {
					transition: $transition-medium;
					opacity: 0.7;
					transform: none;
				}
			}

			.flagActive {
				cursor: default;
				opacity: 0.3;

				&:hover {
					opacity: 0.3;
					transform: none;
				}
			}
		}
	}
}

///////////////////////
// FOOTER
///////////////////////
footer {
	@include flexRowSpaceBetween();
	// position: sticky;

	width: calc(min($maxWidth, 100vw) - 4em);
	margin: 0 auto;
	bottom: 0;
	padding: 1.5em 2em;
	background: $gradient-semi_transparant_white;
	z-index: 10;
	border-radius: 1em 1em 0 0;

	@media screen and (min-width: $minWidthWeb) {
		align-items: center;
	}

	nav {
		display: flex;
		color: $color-secondary;

		@media screen and (max-width: $maxWidthMobile) {
			flex-direction: column;
			gap: 0.5em;
		}

		@media screen and (min-width: $minWidthWeb) {
			flex-direction: row;
			gap: 2em;
		}
	}

	a {
		font-weight: 400;
		opacity: 0.7;

		&:hover {
			opacity: 1;
		}

		&:active {
			opacity: 1;
			transform: scale(0.95);
		}
	}

	.rights {
		text-align: right;
		margin: 0;
	}
}

///////////
// CARDS //
///////////
.card {
	@include basicCard();
	// @include basicCardLink();
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.5em;

	img {
		width: 5em;
		height: 5em;
	}

	.card-text {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 0.5em;
	}
}

.infoCard {
	align-items: center;
	width: 30em;
	padding: 1.5em 2em;

	.card-text {
		h2 {
			font-size: 1.2em;
			color: hsl($hue-primary, 80%, 20%);
			font-weight: 600;
			margin: 0;
			padding: 0;
		}

		p {
			font-size: 1.1em;
			margin: 0;
			padding: 0;
		}
	}

	.imgBig {
		width: 4em;
		height: auto;

		@media screen and (max-width: 530px) {
			display: none;
		}
	}


	.imgSmall {
		height: 1.5em;
		width: auto;

		@media screen and (min-width: 531px) {
			display: none;
		}
	}

	.flexRow {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 0.5em;
	}
}

///////////////////////
// SEARCH 
///////////////////////
.q-and-a-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 20em;
	gap: 4em;


	.question {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		width: 100%;

		h2 {
			background-color: white;
			border-radius: 2em;
			padding: 1em 2em;
			max-width: 70%;
			height: 100%;

			@media screen and (max-width: 431px) {
				max-width: 90%;
			}

		}
	}

	.answer {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;


	}
}

.search-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	gap: 1em;
	padding: 1em;
	width: 100%;
	background-color: white;
	border-radius: 2em;
	padding: 0.5em 0.5em 0.5em 2em;

	.search-input {
		width: 100%;
		min-height: 3em;
		display: flex;
		justify-content: flex-start;
		align-items: center;

	}

	.search-button {
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 3em;
		width: 3em;
		padding: 0.7em;

		svg {
			border-radius: 50%;
		}

	}

	input {
		border: none;
		box-shadow: none;
		padding: 0;

		font-size: 1.1em;
		line-height: 2em;
		color: hsl($hue-primary, 60%, 30%);
		text-align: left;
		font-weight: 400;
	}
}


///////////////////////
// LOADING
///////////////////////
.loadingContainer {
	@include modalBase();

	.loadingAnimation {
		@include modalContent();
		margin-top: 30vh;
		width: 50px;
		padding: 8px;
		aspect-ratio: 1;
		border-radius: 50%;
		background: #25b09b;
		--_m:
			conic-gradient(#0000 10%, #000),
			linear-gradient(#000 0 0) content-box;
		-webkit-mask: var(--_m);
		mask: var(--_m);
		-webkit-mask-composite: source-out;
		mask-composite: subtract;
		animation: l3 1s infinite linear;
	}

	@keyframes l3 {
		to {
			transform: rotate(1turn)
		}
	}
}

///////////
// ALERT //
///////////
.alert {
	@include modalBase();
	z-index: 10;

	.alert-content {
		@include modalContent();
		@include basicCard();
		border-radius: 2em;
		padding: 2em;
		top: 20vh;
		opacity: 1;
		background: $color-off_white;
		max-width: 30em;

		button {
			margin-top: 1.5em;
		}
	}

	.success {
		h2 {
			color: hsl(var(--hue-correct), var(--saturation-lower), var(--low));
		}

		button {
			background: $gradient-completed;
		}
	}

	.error {
		h2 {
			color: hsl(var(--hue-incorrect), var(--saturation-low), var(--low));
		}

		button {
			background: $gradient-incorrect;
			color: rgb(var(--white), var(--white), var(--white));
		}
	}
}